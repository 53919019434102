
import { Modal } from 'bootstrap';
import { defineComponent, ref, inject, watchEffect } from 'vue';
import { Input, Select } from '@/components/input-elements';
import { Apollo, Notify } from '@/core/services';
import { AddTaxRuleForm } from '../interfaces/index';
import { GET_TAX } from '../../taxes/graphql/Queries';
import { GET_COUNTRIES } from '@/modules/common/countries/graphql/Queries';
import { GET_TAX_RULE } from '../graphql/Queries';
import { CREATE_TAX_RULE } from '../graphql/Mutations';
import { GET_LOCALES } from '@/modules/common/locales/graphql/Queries';
import { useI18n } from 'vue-i18n';
import InnerLoader from '@/components/InnerLoader.vue';

export default defineComponent({
    name: 'add new tax rule',
    components: {
        Input,
        Select,
        InnerLoader
    },

    setup() {
        const modal: any = ref();
        const loading = ref(false);
        const emitter: any = inject('emitter');
        const i18n = useI18n();
        const taxRuleForm = ref<null | HTMLFormElement>(null);
        const taxes = ref([]) as Record<any, any>;
        const countries = ref([]);
        const locales = ref([]) as Record<any, any>;
        const locale = ref('en');
        const show_tax_info = ref([]) as Record<any, any>;

        // Given Add Tax Rule Form Interface
        const taxRuleData = ref<AddTaxRuleForm>({
            name: '',
            tax_id: null,
            countries: []
        });

        // Store Name in Danish and English
        const jsonNameFields = ref({}) as Record<any, any>;

        const loadLang = () => {
            const locales_data = Apollo.readQuery({
                query: GET_LOCALES
            });
            locales_data.locales.forEach(element => {
                locales.value.push({
                    label: element.locale.charAt(0).toUpperCase() + element.locale.slice(1),
                    value: element.locale
                });

                jsonNameFields.value[element.locale] = { name: '' };
            });
        };

        loadLang();

        // On change input value
        const changeLang = (e: string) => {
            taxRuleData.value.name = jsonNameFields.value[e].name;
        };

        // update on change value input
        const updateValue = (e: string) => {
            jsonNameFields.value[locale.value].name = e;
        };

        const taxesList = ref([]);
        // Get Tax list
        const getTax = () => {
            Apollo.watchQuery({
                query: GET_TAX,
                fetchPolicy: 'network-only',
                nextFetchPolicy: 'network-only'
            }).subscribe(({ data }) => {
                taxesList.value = [];
                taxesList.value = data.taxes.data;
            });
        };

        watchEffect(() => {
            taxes.value = [];
            taxesList.value.forEach((element: Record<any, any>) => {
                let defaultLocale = '' as any;
                if (JSON.parse(element.name)[`${locale.value}`] == undefined) {
                    defaultLocale = 'en';
                } else {
                    defaultLocale = locale.value;
                }
                taxes.value.push({
                    value: element.id,
                    label: JSON.parse(element.name)[`${defaultLocale}`].name,
                    price: element.rate,
                    type: element.rate_type
                });
            });
        });

        // Get Countries list
        const getCountries = () => {
            const countries_data = Apollo.readQuery({
                query: GET_COUNTRIES
            });

            countries.value = countries_data.countries.map((single: Record<any, any>) => {
                const country = {};
                country['label'] = single.name;
                country['value'] = single.id;
                return country;
            });
        };
        // Submit Handler Request
        const submitHandlerForm = async () => {
            taxRuleForm.value?.validate(async (valid: boolean) => {
                if (valid) {
                    for (const i in jsonNameFields.value) {
                        if (jsonNameFields.value[i].name == '') {
                            jsonNameFields.value[i].name = taxRuleData.value.name;
                        }
                    }
                    const formData = {
                        name: JSON.stringify(jsonNameFields.value),
                        tax_id: taxRuleData.value.tax_id,
                        countries: JSON.stringify(taxRuleData.value.countries)
                    };

                    loading.value = true;
                    await Apollo.mutate({
                        mutation: CREATE_TAX_RULE,
                        variables: { input: formData },
                        update: (store, { data: { create_tax_rule } }) => {
                            const pervious_record = store.readQuery({
                                query: GET_TAX_RULE,
                                variables: {
                                    page: 1,
                                    limit: 10
                                }
                            }) as Record<any, any>;
                            store.writeQuery({
                                query: GET_TAX_RULE,
                                variables: {
                                    page: 1,
                                    limit: 10
                                },
                                data: {
                                    tax_rules: {
                                        ...pervious_record.tax_rules,
                                        data: [create_tax_rule, ...pervious_record.tax_rules.data]
                                    }
                                }
                            });
                            modal.value.hide();
                            loading.value = false;
                            Notify.success(`${i18n.t('message.RECORD_ADDED_SUCCESSFULLY')}`);
                        }
                    }).catch(() => {
                        loading.value = false;
                    });
                }
            });
        };

        const changeTax = (id: number) => {
            show_tax_info.value = [];
            const tax = taxes.value.find(element => element.value == id);
            show_tax_info.value.push({
                'message.TAX_RATE_TYPE': tax.type,
                'message.TAX_RATE': tax.price
            });
        };

        // Reset Form Data On Model Popup
        const resetForm = () => {
            show_tax_info.value = [];
            locale.value = 'en';
            getTax();
            getCountries();
            emitter.emit('clearInput');
            for (const i of Object.getOwnPropertyNames(jsonNameFields.value)) {
                jsonNameFields.value[i].name = '';
            }
            taxRuleForm.value?.resetFields();
        };

        // Emitter To Open Model
        emitter.on('addTaxRuleAction', () => {
            resetForm();
            modal.value = new Modal(document.getElementById('modal_add_tax_rule')) as HTMLElement;
            modal.value.show();
        });

        return {
            taxes,
            loading,
            countries,
            taxRuleData,
            taxRuleForm,
            show_tax_info,
            locales,
            locale,
            changeLang,
            updateValue,
            submitHandlerForm,
            changeTax
        };
    }
});
